






































































































































































































































































.theImgno {
    .el-icon-circle-close {
      color: white;
    }
  }
